import { ref } from 'vue'

export function useDraggable(options = {}) {
  const {
    onDragStart,
    onDrag,
    onDragEnd,
    threshold = 0.3,
    direction = 'vertical',
  } = options

  const startPosition = ref({ x: 0, y: 0 })
  const currentPosition = ref({ x: 0, y: 0 })
  const isDragging = ref(false)

  function startDrag(event) {
    isDragging.value = true
    startPosition.value = {
      x: event.touches[0].clientX,
      y: event.touches[0].clientY,
    }
    currentPosition.value = { ...startPosition.value }
    onDragStart?.(event)
  }

  function drag(event) {
    if (!isDragging.value) return

    currentPosition.value = {
      x: event.touches[0].clientX,
      y: event.touches[0].clientY,
    }

    const delta = {
      x: currentPosition.value.x - startPosition.value.x,
      y: currentPosition.value.y - startPosition.value.y,
    }

    onDrag?.(delta, event)
  }

  function endDrag(event) {
    if (!isDragging.value) return

    isDragging.value = false
    const delta = {
      x: currentPosition.value.x - startPosition.value.x,
      y: currentPosition.value.y - startPosition.value.y,
    }

    const distance = direction === 'vertical' ? delta.y : delta.x
    const dimension =
      direction === 'vertical' ? window.innerHeight : window.innerWidth
    const exceededThreshold = Math.abs(distance) > dimension * threshold

    onDragEnd?.(exceededThreshold, delta, event)
  }

  return {
    isDragging,
    startDrag,
    drag,
    endDrag,
  }
}
