<template>
  <div v-if="error" class="error-boundary pt-safe-top">
    <h2 class="text-secondary">Something went wrong</h2>
    <p>{{ error.message }}</p>
    <button
      class="bg-primary text-primary-foreground px-4 py-2 rounded hover:bg-primary-dark transition-colors duration-200"
      @click="resetError"
    >
      Try again
    </button>
  </div>
  <slot v-else></slot>
</template>

<script setup>
import { ref, onErrorCaptured } from 'vue'
import { logger } from '@/utils/logger'

const error = ref(null)

onErrorCaptured((err, instance, info) => {
  error.value = err

  try {
    // Create a safe error object with only necessary information
    const errorInfo = {
      message: err?.message || 'Unknown error',
      component:
        instance?.$.type?.name || instance?.$.type?.displayName || 'Unknown',
      info: typeof info === 'string' ? info : 'render function',
    }

    // Log error using logger
    logger.error(
      {
        store: 'ErrorBoundary',
        method: 'onErrorCaptured',
      },
      'Vue error captured:',
      errorInfo
    )
  } catch (logError) {
    // Fallback to console if logger fails
    console.error('Error in ErrorBoundary:', err)
    console.error('Logger error:', logError)
  }

  return true // Prevent error from propagating
})

function resetError() {
  error.value = null
}
</script>
