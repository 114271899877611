import { computed } from 'vue'

export function useColorGradient(bookColors) {
  // Compute background style with identical behavior to original
  const currentBackgroundStyle = computed(() => {
    if (!bookColors.value || Object.keys(bookColors.value).length === 0) {
      console.log('No book colors provided, using default background')
      return getDefaultGradient()
    }

    const { lightVibrant, lightMuted } = bookColors.value
    if (lightVibrant && lightMuted) {
      console.log('Generating gradient with colors:', {
        lightVibrant,
        lightMuted,
      })
      return {
        background: `linear-gradient(to bottom, ${adjustColor(lightMuted, 20)}, ${adjustColor(lightVibrant, 20)}, ${adjustColor(lightVibrant, 20)}, ${adjustColor(lightMuted, 20)})`,
      }
    }

    console.log('Missing some colors, using fallback gradient')
    return getFallbackGradient(lightVibrant || lightMuted)
  })

  // Compute play button background style to match original behavior
  const playButtonStyle = computed(() => {
    if (!bookColors.value?.lightMuted) {
      return {
        backgroundColor: 'rgb(75, 85, 99, 0.2)', // gray-600 with 0.2 opacity
      }
    }

    return {
      '--play-button-bg': bookColors.value.lightMuted,
      backgroundColor: `rgb(var(--play-button-bg) / 0.2)`,
    }
  })

  function getDefaultGradient() {
    return {
      background: 'linear-gradient(to bottom, #f0f0f0, #e0e0e0)',
    }
  }

  function getFallbackGradient(color) {
    if (!color) return getDefaultGradient()
    const lighterColor = adjustColor(color, 20)
    const darkerColor = adjustColor(color, -20)
    return {
      background: `linear-gradient(to bottom, ${lighterColor}, ${darkerColor})`,
    }
  }

  function adjustColor(color, amount) {
    const rgb = hexToRgb(color)
    if (!rgb) return color // Handle invalid hex colors gracefully

    const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b)
    hsl[2] = Math.min(100, Math.max(0, hsl[2] + amount)) // Ensure lightness stays between 0-100
    const adjustedRgb = hslToRgb(hsl[0], hsl[1], hsl[2])
    return rgbToHex(adjustedRgb[0], adjustedRgb[1], adjustedRgb[2])
  }

  // Color conversion helper functions (unchanged)
  function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  }

  function rgbToHsl(r, g, b) {
    r /= 255
    g /= 255
    b /= 255
    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h, s
    const l = (max + min) / 2

    if (max === min) {
      h = s = 0
    } else {
      const d = max - min
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0)
          break
        case g:
          h = (b - r) / d + 2
          break
        case b:
          h = (r - g) / d + 4
          break
      }
      h /= 6
    }

    return [h * 360, s * 100, l * 100]
  }

  function hslToRgb(h, s, l) {
    h /= 360
    s /= 100
    l /= 100
    let r, g, b

    if (s === 0) {
      r = g = b = l
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1
        if (t > 1) t -= 1
        if (t < 1 / 6) return p + (q - p) * 6 * t
        if (t < 1 / 2) return q
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
        return p
      }

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s
      const p = 2 * l - q
      r = hue2rgb(p, q, h + 1 / 3)
      g = hue2rgb(p, q, h)
      b = hue2rgb(p, q, h - 1 / 3)
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
  }

  function rgbToHex(r, g, b) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
  }

  return {
    currentBackgroundStyle,
    playButtonStyle,
  }
}
