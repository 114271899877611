import { Transmit } from '@adonisjs/transmit-client'

export const createTransmit = getToken => {
  console.log('Creating new Transmit instance')
  const transmit = new Transmit({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    beforeSubscribe: async request => {
      try {
        const token = getToken()
        if (!token) {
          console.warn('No auth token available for Transmit subscription')
          return
        }

        // Ensure headers is a Headers instance
        if (!(request.headers instanceof globalThis.Headers)) {
          request.headers = new globalThis.Headers(request.headers)
        }

        request.headers.set('Authorization', `Bearer ${token}`)
      } catch (error) {
        console.error('Error in Transmit beforeSubscribe:', error)
        throw error
      }
    },
    onError: error => {
      console.error('Transmit connection error:', error)
    },
    reconnection: {
      retries: 10,
      strategy: 'exponential',
      maxDelay: 10000,
      onRetry: (attempt, delay) => {
        console.log(`Attempting reconnection (${attempt}/10) in ${delay}ms`)
      },
    },
  })

  // Enhanced connection event handling
  transmit.on('connection', connection => {
    console.log('🔌 Transmit connection established')

    connection.on('ready', () => {
      console.log('✅ Transmit connection ready')
    })

    connection.on('error', error => {
      console.error('❌ Transmit connection error:', error)
    })

    connection.on('close', () => {
      console.log('🔌 Transmit connection closed')
    })

    // Add reconnecting event
    connection.on('reconnecting', () => {
      console.log('🔄 Transmit attempting to reconnect...')
    })
  })

  return transmit
}
