import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
  state: () => ({
    viewsReady: new Set(),
    appInitialized: false,
  }),

  actions: {
    setViewReady(viewName) {
      this.viewsReady.add(viewName)
      this.checkAppReady()
    },

    checkAppReady() {
      // Add conditions for when the app is considered fully ready
      const requiredViews = ['stories'] // Add other critical views if needed
      const allViewsReady = requiredViews.every(view =>
        this.viewsReady.has(view)
      )

      if (allViewsReady && !this.appInitialized) {
        this.appInitialized = true
      }
    },
  },
})
