import { ref, computed } from 'vue'
import { useQueueStore } from '@/stores/queue'

export function useQueue() {
  const queueStore = useQueueStore()
  const isQueueOpen = ref(false)
  const queueMaxHeight = ref(200)

  function calculateQueueHeight(containerRef, queueRef) {
    if (!containerRef || !queueRef) return

    if (!containerRef.value || !queueRef.value) return

    const windowHeight = window.innerHeight
    const mainContentHeight =
      containerRef.value.offsetHeight - (queueRef.value?.offsetHeight || 0)
    queueMaxHeight.value = Math.max(100, windowHeight - mainContentHeight - 100)
  }

  function toggleQueue() {
    isQueueOpen.value = !isQueueOpen.value
  }

  const hasQueueItems = computed(() => queueStore.queue.length > 0)

  return {
    isQueueOpen,
    queueMaxHeight,
    hasQueueItems,
    queue: computed(() => queueStore.queue),
    calculateQueueHeight,
    toggleQueue,
  }
}
