<template>
  <div
    ref="slidingWindowRef"
    class="fixed inset-0 transform transition-all duration-500 ease-out rounded-t-xl z-60 pt-safe-top pb-safe-bottom px-3.5 flex flex-col touch-none overscroll-none"
    :class="{
      'translate-y-full': !isOpen,
      'translate-y-0': isOpen,
    }"
    :style="[currentBackgroundStyle, { isolation: 'isolate' }]"
    @touchstart.passive="startDrag"
    @touchmove.passive="drag"
    @touchend.passive="endDrag"
  >
    <!-- Handle -->
    <div>
      <div
        class="h-1 w-24 mx-auto mt-1 rounded-full z-60 relative"
        :class="bgClass"
        :style="{ backgroundColor: bookColors?.lightMuted || 'gray' }"
      />
    </div>

    <!-- Header -->
    <div
      class="flex justify-between items-center px-4 pt-2 transition-all duration-300"
    >
      <HugeiconsIcon
        :icon="ArrowDown01Icon"
        :size="32"
        color="currentColor"
        :strokeWidth="0.5"
        class="cursor-pointer"
        @click="closeWindow"
      />
      <span class="text-md font-semibold">Now Playing</span>
      <HugeiconsIcon
        :icon="MoreHorizontalIcon"
        :size="32"
        color="currentColor"
        :strokeWidth="0.5"
      />
    </div>

    <!-- Main Content (non-scrollable) -->
    <div class="flex-1 flex flex-col overflow-hidden">
      <!-- Story Cover -->
      <div
        class="px-4 transition-all duration-300 ease-in-out flex justify-center"
        :class="{
          'mt-8': !isQueueOpen,
        }"
      >
        <div
          class="w-full transition-all duration-500 ease-in-out"
          :style="{
            transform: isQueueOpen ? 'scale(0.9)' : 'scale(1)',
          }"
        >
          <img
            :src="coverArtUrl"
            alt="Story Cover"
            class="w-full h-auto object-cover transition-all duration-500 ease-in-out will-change-transform rounded-lg shadow-lg"
          />
        </div>
      </div>

      <!-- Story Info -->
      <div
        class="px-4 text-center transition-all duration-300 ease-in-out"
        :class="{
          'mt-2': isQueueOpen,
          'mt-6': !isQueueOpen,
        }"
      >
        <h2 class="text-md font-serif font-semibold">
          {{ title }}
        </h2>
      </div>

      <!-- Progress Bar -->
      <div
        class="px-4 transition-all duration-300 ease-in-out"
        :class="{
          'mt-2': isQueueOpen,
          'mt-6': !isQueueOpen,
        }"
      >
        <div
          ref="progressBarRef"
          class="bg-gray-300 h-1 rounded-full relative cursor-pointer touch-none select-none"
          @mousedown="startProgressDrag"
          @mousemove="handleProgressDrag"
          @mouseup="endProgressDrag"
          @mouseleave="endProgressDrag"
          @touchstart.passive="startProgressTouch"
          @touchmove.passive="handleProgressTouch"
          @touchend="endProgressTouch"
        >
          <div
            class="bg-gray-600 h-1 rounded-full absolute top-0 left-0 transition-[width] duration-75"
            :style="{ width: `${progressPercentage}%` }"
          ></div>
        </div>
        <div class="flex justify-between text-xs mt-1">
          <span>{{ formattedCurrentTime }}</span>
          <span>{{ formattedDuration }}</span>
        </div>
      </div>

      <!-- Controls -->
      <div
        class="px-4 transition-all duration-300 ease-in-out"
        :class="{
          'mt-2': isQueueOpen,
          'mt-4': !isQueueOpen,
        }"
      >
        <div class="flex justify-center items-center">
          <div class="flex items-center space-x-4 sm:space-x-8">
            <HugeiconsIcon
              :icon="Backward01Icon"
              :size="32"
              color="currentColor"
              :strokeWidth="0.1"
              class="sm:w-9 sm:h-9 text-gray-600 cursor-pointer"
              @click="skipBack"
            />
            <HugeiconsIcon
              :icon="GoBackward15SecIcon"
              :size="32"
              color="currentColor"
              :strokeWidth="0.1"
              class="sm:w-9 sm:h-9 text-gray-600 cursor-pointer"
              @click="skipBack"
            />
            <div
              class="rounded-full p-3 cursor-pointer"
              :class="[
                bookColors?.lightMuted
                  ? 'bg-[var(--play-button-bg)]/20'
                  : 'bg-gray-600/20',
              ]"
              :style="
                bookColors?.lightMuted
                  ? { '--play-button-bg': bookColors.lightMuted }
                  : {}
              "
              @click="togglePlayPause"
            >
              <HugeiconsIcon
                :icon="isPlaying ? PauseIcon : PlayIcon"
                :size="40"
                color="currentColor"
                :strokeWidth="0.1"
                class="text-gray-600 cursor-pointer"
              />
            </div>
            <HugeiconsIcon
              :icon="GoForward15SecIcon"
              :size="32"
              color="currentColor"
              :strokeWidth="0.1"
              class="sm:w-9 sm:h-9 text-gray-600 cursor-pointer"
              @click="skipForward"
            />
            <HugeiconsIcon
              :icon="Forward01Icon"
              :size="32"
              color="currentColor"
              :strokeWidth="0.1"
              class="sm:w-9 sm:h-9 text-gray-600 cursor-pointer"
              @click="skipForward"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Next in Queue Section (scrollable) -->
    <!-- Next in Queue Section (scrollable) -->
    <Transition
      @enter="enterQueue"
      @leave="leaveQueue"
      @after-enter="afterEnterQueue"
      @after-leave="afterLeaveQueue"
    >
      <div
        v-if="isQueueOpen"
        ref="queueRef"
        class="px-4 pb-4 border-t border-gray-300 overflow-hidden shrink-0"
        :style="{ maxHeight: queueMaxHeight + 'px' }"
      >
        <h3 class="text-lg font-semibold mb-2">Next in Queue</h3>
        <!-- Scrollable queue list -->
        <div
          class="overflow-y-auto"
          :style="{ maxHeight: queueMaxHeight - 40 + 'px' }"
        >
          <template v-if="queueStore.queue.length > 0">
            <ul>
              <li
                v-for="(item, index) in queueStore.queue"
                :key="item.id"
                class="text-gray-700 mb-2 flex items-center"
              >
                <img
                  :src="item.bookCoverUrl"
                  :alt="item.title"
                  class="w-10 h-10 object-cover mr-2 rounded"
                />
                <div>
                  <div class="text-xs font-semibold">{{ item.title }}</div>
                </div>
              </li>
            </ul>
          </template>
          <template v-else>
            <div
              class="flex flex-col items-center justify-center py-8 text-gray-500"
            >
              <p class="text-sm">No items in queue</p>
              <p class="text-xs mt-1">
                Add stories to your queue to listen next
              </p>
            </div>
          </template>
        </div>
      </div>
    </Transition>

    <!-- Footer -->
    <div
      class="mt-auto transition-all duration-300 ease-in-out"
      :class="{
        'mb-4': !isQueueOpen,
        'mb-2': isQueueOpen,
        'sm:mb-8': true, // More bottom margin on desktop
      }"
    >
      <div class="flex justify-between items-center px-4">
        <!-- Only show speaker button for native apps -->
        <span v-if="isCapacitor">
          <button
            class="p-2 rounded-full hover:bg-gray-200 transition"
            @click="showMediacastPicker"
          >
            <HugeiconsIcon
              :icon="SpeakerIcon"
              :size="24"
              color="currentColor"
              :strokeWidth="0.5"
              class="text-gray-600"
            />
          </button>
        </span>
        <span v-else class="w-8"></span>

        <HugeiconsIcon
          :icon="LeftToRightListBulletIcon"
          :size="24"
          color="currentColor"
          :strokeWidth="0.5"
          class="cursor-pointer"
          @click="toggleQueue"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, nextTick, watch } from 'vue'
import { HugeiconsIcon } from '@hugeicons/vue'
import {
  ArrowDown01Icon,
  MoreHorizontalIcon,
  Backward01Icon,
  GoBackward15SecIcon,
  PlayIcon,
  PauseIcon,
  GoForward15SecIcon,
  Forward01Icon,
  SpeakerIcon,
  LeftToRightListBulletIcon,
} from '@hugeicons-pro/core-stroke-rounded'
import { useQueueStore } from '@/stores/queue'
import { useColorGradient } from '@/composables/useColorGradient'
import { useDraggable } from '@/composables/useDraggable'
import { useProgress } from '@/composables/useProgress'
import { useQueue } from '@/composables/useQueue'
import { usePlatform } from '@/composables/usePlatform'

const queueStore = useQueueStore()

const props = defineProps({
  isOpen: Boolean,
  title: String,
  coverArtUrl: String,
  isPlaying: Boolean,
  currentTime: Number,
  duration: Number,
  bookColors: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits([
  'update:isOpen',
  'togglePlayPause',
  'skipBack',
  'skipForward',
  'seek',
])

const slidingWindowRef = ref(null)
const queueRef = ref(null)
const progressBarRef = ref(null)
const isProgressDragging = ref(false)

const { currentBackgroundStyle } = useColorGradient(
  computed(() => props.bookColors)
)
const { showMediacastPicker, isCapacitor } = usePlatform()
const { progressPercentage, formattedCurrentTime, formattedDuration } =
  useProgress(
    computed(() => props.currentTime),
    computed(() => props.duration)
  )
const { isQueueOpen, queueMaxHeight, calculateQueueHeight, toggleQueue } =
  useQueue()

const { startDrag, drag, endDrag } = useDraggable({
  onDragEnd: exceededThreshold => {
    if (exceededThreshold) {
      emit('update:isOpen', false)
    } else {
      slidingWindowRef.value.style.transform = ''
    }
  },
})

const bgClass = computed(() => {
  return props.bookColors?.lightMuted ? 'bg-opacity-50' : ''
})

function closeWindow() {
  emit('update:isOpen', false)
}

function togglePlayPause() {
  emit('togglePlayPause')
}

function skipBack() {
  emit('skipBack')
}

function skipForward() {
  emit('skipForward')
}

function calculateProgressPercentage(event) {
  if (!progressBarRef.value) return 0

  const rect = progressBarRef.value.getBoundingClientRect()
  const x = event.type.startsWith('touch')
    ? event.touches[0].clientX - rect.left
    : event.clientX - rect.left

  // Ensure percentage is between 0 and 100
  const percentage = Math.max(0, Math.min(100, (x / rect.width) * 100))
  return percentage
}

function updateProgress(percentage) {
  if (!isProgressDragging.value) return
  const seekTime = (percentage / 100) * props.duration
  emit('seek', seekTime)
}

// Mouse events
function startProgressDrag(event) {
  isProgressDragging.value = true
  const percentage = calculateProgressPercentage(event)
  updateProgress(percentage)
}

function handleProgressDrag(event) {
  if (!isProgressDragging.value) return
  const percentage = calculateProgressPercentage(event)
  updateProgress(percentage)
}

function endProgressDrag() {
  isProgressDragging.value = false
}

// Touch events
function startProgressTouch(event) {
  isProgressDragging.value = true
  const percentage = calculateProgressPercentage(event)
  updateProgress(percentage)
}

function handleProgressTouch(event) {
  if (!isProgressDragging.value) return
  const percentage = calculateProgressPercentage(event)
  updateProgress(percentage)
}

function endProgressTouch() {
  isProgressDragging.value = false
}

// Queue transition methods
function enterQueue(el, done) {
  // Implement enter animation
  done()
}

function leaveQueue(el, done) {
  // Implement leave animation
  done()
}

function afterEnterQueue() {
  // Actions after enter animation
}

function afterLeaveQueue() {
  // Actions after leave animation
}

// Call this function when the component mounts and when the window resizes
onMounted(() => {
  nextTick(() => {
    // Pass both refs to calculateQueueHeight
    calculateQueueHeight(slidingWindowRef, queueRef)
    window.addEventListener('resize', () =>
      calculateQueueHeight(slidingWindowRef, queueRef)
    )
  })
})

onUnmounted(() => {
  window.removeEventListener('resize', () =>
    calculateQueueHeight(slidingWindowRef, queueRef)
  )
})

// Watch for changes in isOpen prop
watch(
  () => props.isOpen,
  newIsOpen => {
    if (newIsOpen) {
      // Reset position
      slidingWindowRef.value.style.transform = ''
    }
  }
)
</script>
