<!-- frontend/src/App.vue -->
<template>
  <div class="app-wrapper h-full">
    <ErrorBoundary>
      <RouterView v-slot="{ Component }">
        <component :is="resolveLayoutComponent(Component)" v-if="Component">
          <BaseTransition
            :use-keep-alive="$route.meta.keepAlive"
            :transition-name="route?.meta?.transition === 'none' ? '' : 'fade'"
            :transition-mode="
              route?.meta?.transition === 'none' ? '' : 'out-in'
            "
          >
            <component :is="Component" :key="$route.fullPath" />
          </BaseTransition>
        </component>
      </RouterView>
    </ErrorBoundary>

    <audio
      ref="audioPlayer"
      class="hidden"
      preload="none"
      @ended="handleAudioEnded"
      @timeupdate="handleTimeUpdate"
      @loadedmetadata="handleLoadedMetadata"
    />

    <Toaster />

    <div
      v-if="!isOnline"
      class="fixed bottom-0 left-0 right-0 bg-yellow-500 text-white p-2 text-center z-50"
    >
      You are currently offline. Some features may be limited.
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch, onUnmounted, inject } from 'vue'
import { useRoute, RouterView } from 'vue-router'
import ErrorBoundary from '@/components/ErrorBoundary.vue'
import BaseTransition from '@/components/BaseTransition.vue'
import { useQueueStore } from '@/stores/queue'
import { useAuthStore } from '@/stores/auth'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { Capacitor } from '@capacitor/core'
import { useNetworkStatus } from '@/composables/useNetworkStatus'
import { Toaster } from '@/components/ui/toast'
import { StatusBar, Style } from '@capacitor/status-bar'
import { SplashScreen } from '@capacitor/splash-screen'

const authStore = useAuthStore()
const queueStore = useQueueStore()
const audioPlayer = ref(null)
const { isOnline } = useNetworkStatus()
const transmit = inject('transmit')

const route = useRoute()

const resolveLayoutComponent = Component => {
  if (!Component) return DefaultLayout
  return Component.type?.layout || route?.meta?.layout || DefaultLayout
}

const isCapacitor = computed(() => Capacitor.isNativePlatform())

onMounted(async () => {
  try {
    if (isCapacitor.value) {
      const platform = Capacitor.getPlatform()

      if (platform === 'android') {
        // Android-specific status bar handling
        // We'll let the SafeArea plugin handle this instead
        console.log(
          'Android platform detected, using SafeArea plugin for system bars'
        )
      } else if (platform === 'ios') {
        // Keep iOS configuration as is
        await StatusBar.setStyle({ style: Style.Light })
        await StatusBar.setBackgroundColor({ color: '#FAFAFA' })
        await StatusBar.show()
      }
    }
    await authStore.initAuth()
    if (authStore.isAuthenticated && audioPlayer.value) {
      await queueStore.setAudioPlayer(audioPlayer.value)
    }
  } catch (error) {
    console.error('Error initializing app:', error)
  }
})

watch(isCapacitor, async isNative => {
  if (isNative) {
    try {
      await SplashScreen.hide({ fadeOutDuration: 500 })
    } catch (error) {
      console.error('Error hiding splash screen:', error)
    }
  }
})

const handleAudioEnded = () => {
  queueStore.handleAudioEnded()
}

const handleTimeUpdate = () => {
  if (audioPlayer.value) {
    queueStore.updateCurrentTime(audioPlayer.value.currentTime)
  }
}

const handleLoadedMetadata = () => {
  if (audioPlayer.value) {
    queueStore.updateDuration(audioPlayer.value.duration)
  }
}

onUnmounted(() => {
  console.log('🧹 App unmounting, cleaning up transmit connections')
  if (transmit) {
    transmit.removeAllListeners?.()
  }
})

watch(
  () => authStore.isAuthenticated,
  async (newValue, oldValue) => {
    console.log('👀 Auth status changed in App:', { newValue, oldValue })
    if (!newValue && oldValue && transmit) {
      console.log('🔌 Cleaning up transmit on logout')
      transmit.removeAllListeners?.()
    }
  }
)
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@supports (padding-top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
    --safe-area-inset-right: env(safe-area-inset-right);
  }
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: rgb(var(--muted));
  overscroll-behavior: none;
  width: 100%;
  height: 100%;
}

.app-wrapper {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  background-color: rgb(var(--muted));
  padding-top: 0;
  margin-top: calc(-1 * var(--safe-area-inset-top));
  width: 100%;
  height: 100%;
  overflow: auto;
}

@supports (height: 100dvh) {
  html,
  body {
    height: 100dvh;
  }

  .app-wrapper {
    min-height: 100dvh;
  }
}

@supports not (height: 100dvh) {
  html,
  body {
    height: -webkit-fill-available;
    height: 100vh;
  }

  .app-wrapper {
    min-height: -webkit-fill-available;
    min-height: 100vh;
  }
}

:root {
  --safe-area-top: env(safe-area-inset-top);
}

body {
  padding-top: var(--safe-area-top);
}
</style>
