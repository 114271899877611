<!-- frontend/src/components/ResponsiveAudioPlayer.vue -->
<template>
  <div
    :class="[
      'fixed left-0 right-0 flex-shrink-0 transition-all duration-300',
      {
        'bottom-[calc(49px+var(--safe-area-inset-bottom))]':
          hasBottomNavbar && !isExpanded && isAndroid,
        'bottom-[calc(49px+env(safe-area-inset-bottom))]':
          hasBottomNavbar && !isExpanded && !isAndroid,
        'bottom-0': !hasBottomNavbar || isExpanded,
      },
    ]"
    :style="{ zIndex: playerZIndex }"
  >
    <!-- Desktop Layout -->
    <div v-if="isDesktop" class="bg-white border-t border-gray-200 h-14">
      <div
        class="max-w-(--breakpoint-xl) mx-auto flex items-center h-full px-4"
      >
        <!-- Left Controls: Skip Back, Play/Pause, Skip Forward -->
        <div class="flex items-center space-x-2 mr-4">
          <button
            class="text-gray-600 hover:text-primary-hover cursor-pointer"
            @click="skipBack"
          >
            <HugeiconsIcon
              :icon="Backward01Icon"
              :size="20"
              color="currentColor"
              :strokeWidth="0.5"
            />
          </button>
          <div
            class="w-9 h-9 bg-white rounded-full flex items-center justify-center cursor-pointer"
            @click="togglePlayPause"
          >
            <HugeiconsIcon
              :icon="isPlaying ? PauseIcon : PlayIcon"
              :size="24"
              color="currentColor"
              :strokeWidth="0.5"
              class="text-gray-800 hover:text-primary-hover"
            />
          </div>
          <button
            class="text-gray-600 hover:text-primary-hover cursor-pointer"
            @click="skipForward"
          >
            <HugeiconsIcon
              :icon="Forward01Icon"
              :size="20"
              color="currentColor"
              :strokeWidth="0.5"
            />
          </button>
        </div>

        <!-- Middle Controls: Shuffle and Repeat -->
        <div class="flex items-center space-x-4 mr-4">
          <button
            class="text-gray-400 hover:text-gray-600 cursor-pointer"
            @click="toggleShuffle"
          >
            <HugeiconsIcon
              :icon="ShuffleIcon"
              :size="20"
              color="currentColor"
              :strokeWidth="0.5"
              :class="{
                'text-secondary hover:text-secondary-hover': isShuffleOn,
              }"
            />
          </button>
          <button
            class="text-gray-400 hover:text-gray-600 cursor-pointer"
            @click="toggleRepeat"
          >
            <HugeiconsIcon
              :icon="RepeatIcon"
              :size="20"
              color="currentColor"
              :strokeWidth="0.5"
              :class="{
                'text-secondary hover:text-secondary-hover': isRepeatOn,
              }"
            />
          </button>
        </div>

        <!-- Progress Bar -->
        <div class="flex-1 mx-4">
          <div class="flex items-center">
            <span
              class="text-xs text-gray-500 font-semibold mr-2 w-8 text-right"
            >
              {{ formattedCurrentTime }}
            </span>
            <div
              class="flex-1 h-1 bg-gray-300 rounded-full relative cursor-pointer touch-none"
              @touchstart.passive="onTouchStart"
              @touchmove.passive="onTouchMove"
              @touchend.passive="onTouchEnd"
              @mousedown="startDrag"
              @mousemove="drag"
              @mouseup="endDrag"
              @mouseleave="endDrag"
              @click="handleClick"
            >
              <div
                class="h-full bg-primary"
                :style="{ width: `${progressPercentage}%` }"
              ></div>
            </div>
            <span class="text-xs text-gray-500 font-semibold ml-2 w-8">
              {{ formattedDuration }}
            </span>
          </div>
        </div>

        <!-- Volume Control -->
        <div class="flex items-center space-x-2 mr-4">
          <button
            class="text-gray-600 hover:text-primary-hover cursor-pointer"
            @click="toggleMute"
          >
            <HugeiconsIcon
              :icon="isMuted ? VolumeOffIcon : VolumeHighIcon"
              :size="16"
              color="currentColor"
              :strokeWidth="0.5"
            />
          </button>
        </div>

        <!-- Right Controls: Cover Art, Title, Favorite, Playlist -->
        <div
          class="flex items-center space-x-3 w-64 cursor-pointer"
          @click="goToStoryDetail"
        >
          <img
            :src="coverArtUrl"
            alt="Current track"
            class="w-8 h-8 rounded shrink-0 cursor-pointer"
          />
          <div class="overflow-hidden grow cursor-pointer">
            <p
              class="text-xs font-medium whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer"
            >
              {{ currentlyPlayingTitle }}
            </p>
          </div>
          <button
            class="text-gray-600 hover:text-primary cursor-pointer"
            @click="toggleFavorite"
          >
            <HugeiconsIcon
              :icon="FavouriteIcon"
              :size="20"
              color="currentColor"
              :strokeWidth="0.5"
            />
          </button>
          <button
            class="text-gray-600 hover:text-primary cursor-pointer"
            @click="openPlaylist"
          >
            <HugeiconsIcon
              :icon="Playlist01Icon"
              :size="20"
              color="currentColor"
              :strokeWidth="0.5"
            />
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile/Tablet Collapsed Layout -->
    <div
      v-else-if="!isExpanded"
      class="bg-white flex flex-col items-center border-t border-gray-200"
    >
      <div
        class="flex items-center justify-between h-12 px-2 sm:px-4 w-full"
        @click="handleMobilePlayerClick"
      >
        <!-- Left: Cover Art and Title -->
        <div class="flex items-center">
          <div class="shrink-0 mr-4">
            <img
              :src="coverArtUrl"
              alt="Current track"
              class="w-10 h-10 rounded cursor-pointer"
            />
          </div>
          <div class="flex flex-col justify-center">
            <p class="text-sm w-64 font-serif truncate cursor-pointer">
              {{ currentlyPlayingTitle }}
            </p>
          </div>
        </div>
        <!-- Right: Controls -->
        <div class="flex items-center space-x-2 shrink-0">
          <button
            v-if="isCapacitor"
            class="p-2 rounded-full hover:bg-gray-200 transition"
            @click.stop="showMediacastPicker"
          >
            <HugeiconsIcon
              :icon="SpeakerIcon"
              :size="24"
              color="currentColor"
              :strokeWidth="0.5"
              class="text-gray-600"
            />
          </button>
          <button
            class="text-gray-600 hover:text-primary-hover cursor-pointer p-2"
            @click.stop="togglePlayPause"
          >
            <HugeiconsIcon
              :icon="isPlaying ? PauseIcon : PlayIcon"
              :size="24"
              color="currentColor"
              :strokeWidth="0.5"
            />
          </button>
        </div>
      </div>
      <!-- Progress Bar -->
      <div
        class="w-full h-0.5 bg-gray-200 relative touch-none"
        @touchstart.passive="onTouchStart"
        @touchmove.passive="onTouchMove"
        @touchend.passive="onTouchEnd"
        @mousedown="startDrag"
        @mousemove="drag"
        @mouseup="endDrag"
        @mouseleave="endDrag"
        @click="handleClick"
      >
        <div
          class="h-full bg-primary transition-width duration-300"
          :style="{ width: `${progressPercentage}%` }"
        ></div>
      </div>
    </div>

    <!-- Sliding Window for Mobile/Tablet -->
    <teleport to="#sliding-window-container">
      <SlidingWindow
        v-if="isExpanded"
        :isOpen="isExpanded"
        :coverArtUrl="coverArtUrl"
        :title="currentlyPlayingTitle"
        :author="queueStore.currentlyPlaying?.author || ''"
        :bookColors="queueStore.currentlyPlaying?.bookColors"
        :currentTime="currentTime"
        :duration="duration"
        :isPlaying="isPlaying"
        :isRepeatOn="isRepeatOn"
        :isShuffleOn="isShuffleOn"
        :isAndroid="isAndroid"
        @update:isOpen="isExpanded = $event"
        @togglePlayPause="togglePlayPause"
        @skipBack="skipBack"
        @skipForward="skipForward"
        @toggleRepeat="toggleRepeat"
        @toggleShuffle="toggleShuffle"
        @seek="seek"
      />
    </teleport>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useQueueStore } from '@/stores/queue'
import { useRoute, useRouter } from 'vue-router'
import { useResponsive } from '@/composables/useResponsive'
import { HugeiconsIcon } from '@hugeicons/vue'
import {
  Backward01Icon,
  Forward01Icon,
  PauseIcon,
  PlayIcon,
  RepeatIcon,
  ShuffleIcon,
  VolumeHighIcon,
  VolumeOffIcon,
} from '@hugeicons-pro/core-solid-rounded'
import {
  FavouriteIcon,
  Playlist01Icon,
  SpeakerIcon,
} from '@hugeicons-pro/core-stroke-rounded'
import { Capacitor } from '@capacitor/core'
import SlidingWindow from '@/components/SlidingWindow.vue'

// Responsive Properties
const { isDesktop, isMobile, isTablet } = useResponsive()

// Platform Detection
const isCapacitor = computed(() => Capacitor.isNativePlatform())
const isIOS = computed(
  () => isCapacitor.value && Capacitor.getPlatform() === 'ios'
)
const isAndroid = computed(() => {
  return Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android'
})

// Store and Router
const queueStore = useQueueStore()
const route = useRoute()
const router = useRouter()

// State
const isExpanded = ref(false)
const duration = ref(0)
const hasBottomNavbar = computed(() => isMobile.value || isTablet.value) // Adjust based on your app's logic

// Audio Player State
const isPlaying = computed(() => queueStore.isPlaying)
const currentTime = computed(() => queueStore.currentlyPlaying?.progress || 0)
const progressPercentage = computed(() => {
  if (!queueStore.audioPlayer || !queueStore.currentlyPlaying) return 0
  return (currentTime.value / duration.value) * 100 || 0
})
const formattedCurrentTime = computed(() => formatTime(currentTime.value))
const formattedDuration = computed(() =>
  formatTime(duration.value || queueStore.audioPlayer?.duration || 0)
)

// Utility Functions
function formatTime(time) {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  return `${minutes}:${seconds.toString().padStart(2, '0')}`
}

// Additional State
const isMuted = computed(() => queueStore.isMuted)
const isShuffleOn = computed(() => queueStore.isShuffleOn)
const isRepeatOn = computed(() => queueStore.isRepeatOn)
const currentlyPlayingTitle = computed(
  () => queueStore.currentlyPlaying?.title || 'No Story Playing'
)
const coverArtUrl = computed(
  () => queueStore.currentlyPlaying?.bookCoverUrl || '/placeholder.svg'
)

// Audio Player Methods
const updateDuration = () => {
  if (queueStore.audioPlayer) {
    duration.value = queueStore.audioPlayer.duration || 0
  }
}

function handleMobilePlayerClick(event) {
  event.preventDefault()
  if (isMobile.value || isTablet.value) {
    isExpanded.value = true
  } else {
    goToStoryDetail()
  }
}

async function showMediacastPicker() {
  if (isIOS.value) {
    try {
      const { default: Mediacast } = await import('@/plugins/mediacast-plugin')
      await Mediacast.showMediacastPicker()
    } catch (error) {
      console.error('Error showing Mediacast picker:', error)
    }
  }
}

async function togglePlayPause() {
  if (isPlaying.value) {
    await queueStore.pause()
  } else if (queueStore.currentlyPlaying) {
    await queueStore.play()
  } else if (route.name === 'story-detail') {
    const storyId = Number(route.params.id)
    await queueStore.play(storyId)
  }
}

function skipBack() {
  if (queueStore.audioPlayer && queueStore.currentlyPlaying) {
    const newTime = Math.max(0, queueStore.audioPlayer.currentTime - 15)
    queueStore.audioPlayer.currentTime = newTime
    queueStore.currentlyPlaying.progress = newTime
  }
}

function skipForward() {
  if (queueStore.audioPlayer && queueStore.currentlyPlaying) {
    const newTime = Math.min(
      queueStore.audioPlayer.duration || 0,
      queueStore.audioPlayer.currentTime + 15
    )
    queueStore.audioPlayer.currentTime = newTime
    queueStore.currentlyPlaying.progress = newTime
  }
}

function seek(newTime) {
  if (queueStore.audioPlayer && queueStore.currentlyPlaying) {
    queueStore.audioPlayer.currentTime = newTime
    queueStore.currentlyPlaying.progress = newTime
  }
}

const toggleShuffle = () => queueStore.toggleShuffle()
const toggleRepeat = () => queueStore.toggleRepeat()
const toggleMute = () => queueStore.toggleMute()

function goToStoryDetail() {
  if (queueStore.currentlyPlaying) {
    router.push({
      name: 'story-detail',
      params: { id: queueStore.currentlyPlaying.id },
    })
  }
}

function toggleFavorite() {
  console.log('Toggle favorite') // Implement your logic
}

function openPlaylist() {
  console.log('Open playlist') // Implement your logic
}

// Progress Bar Interactivity
function handleClick(event) {
  event.preventDefault()
  const clientX = event.type.startsWith('touch')
    ? event.touches[0].clientX
    : event.clientX
  const rect = event.target.getBoundingClientRect()
  const x = clientX - rect.left
  const clickedPercentage = x / rect.width
  const newTime = clickedPercentage * duration.value
  seek(newTime)
}

const startX = ref(0)
const currentX = ref(0)
const isDragging = ref(false)

function onTouchStart(event) {
  startX.value = event.touches[0].clientX
  isDragging.value = true
}

function onTouchMove(event) {
  if (!isDragging.value) return
  currentX.value = event.touches[0].clientX
  const rect = event.target.getBoundingClientRect()
  const percentage = (currentX.value - rect.left) / rect.width
  const newTime = Math.max(
    0,
    Math.min(duration.value, percentage * duration.value)
  )
  seek(newTime)
}

function onTouchEnd() {
  isDragging.value = false
}

function startDrag(event) {
  event.preventDefault()
  isDragging.value = true
  startX.value = event.clientX
}

function drag(event) {
  if (!isDragging.value) return
  event.preventDefault()
  currentX.value = event.clientX
  const rect = event.target.getBoundingClientRect()
  const percentage = (currentX.value - rect.left) / rect.width
  const newTime = Math.max(
    0,
    Math.min(duration.value, percentage * duration.value)
  )
  seek(newTime)
}

function endDrag(event) {
  if (!isDragging.value) return
  event.preventDefault()
  isDragging.value = false
}

// Lifecycle and Watchers
watch(
  () => queueStore.audioPlayer,
  newPlayer => {
    if (newPlayer) {
      updateDuration()
      newPlayer.addEventListener('loadedmetadata', updateDuration)
      newPlayer.addEventListener('durationchange', updateDuration)
    }
  },
  { immediate: true }
)

onMounted(() => {
  if (queueStore.audioPlayer) {
    updateDuration()
    queueStore.audioPlayer.addEventListener('loadedmetadata', updateDuration)
    queueStore.audioPlayer.addEventListener('durationchange', updateDuration)
  }
})

onUnmounted(() => {
  if (queueStore.audioPlayer) {
    queueStore.audioPlayer.removeEventListener('loadedmetadata', updateDuration)
    queueStore.audioPlayer.removeEventListener('durationchange', updateDuration)
  }
})

// New computed property for z-index stacking order
const playerZIndex = computed(() => {
  // Always keep the audio player above the bottom navbar
  // When expanded, use z-60 to match SlidingWindow
  return isExpanded.value ? 60 : 40
})
</script>

<style scoped>
.touch-none {
  touch-action: none;
}
</style>
