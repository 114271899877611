import './assets/main.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'
import { SafeArea, initialize } from '@capacitor-community/safe-area'
import Vue3TouchEvents from 'vue3-touch-events'
import Notifications, { notify } from '@kyvg/vue3-notification'
import { registerSW } from 'virtual:pwa-register'

import App from './App.vue'
import router from './router'
import { handleError } from './utils/errorHandler'
import { logger } from './utils/logger'
import { useAuthStore } from './stores/auth'
import { createTransmit } from './services/transmit'

initialize()

const initializePWA = () => {
  const updateSW = registerSW({
    onNeedRefresh() {
      if (confirm('New content available. Reload?')) {
        updateSW(true)
      }
    },
    onOfflineReady() {
      console.log('App ready to work offline')
      notify({
        title: 'Offline Ready',
        text: 'App is ready for offline use',
        type: 'success',
      })
    },
    immediate: true,
  })
  return updateSW
}

const SENTRY_ENVIRONMENT =
  import.meta.env.VITE_SENTRY_ENVIRONMENT || 'development'
const SENTRY_RELEASE = import.meta.env.VITE_SENTRY_RELEASE || 'development'

const initializeSentry = app => {
  if (process.env.NODE_ENV !== 'production') return

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.2 : 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api\.talebly\.com\//,
      /^https:\/\/api\.dev\.talebly\.com\//,
      /^https:\/\/tunnel\.dev\.talebly\.com\//,
    ],
    replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: process.env.NODE_ENV === 'production' ? 0.2 : 1.0,
    release: SENTRY_RELEASE,
    environment: SENTRY_ENVIRONMENT,
  })
}

const initializeNativePlatform = async () => {
  if (!Capacitor.isNativePlatform()) return null

  try {
    const platform = Capacitor.getPlatform()

    if (platform === 'android') {
      // Android-specific configuration
      await SafeArea.enable({
        config: {
          customColorsForSystemBars: true,
          statusBarColor: '#FFFFFF', // White status bar (was transparent)
          statusBarContent: 'dark',
          navigationBarColor: '#FFFFFF', // White navigation bar
          navigationBarContent: 'dark',
          navigationBarDividerColor: '#E5E7EB', // Adding a divider color that matches border-gray-200
          offset: 0,
        },
      })
    } else if (platform === 'ios') {
      // Keep iOS configuration as is
      await StatusBar.setStyle({ style: Style.Light })
      await StatusBar.setBackgroundColor({ color: '#FAFAFA' })
    }

    return true
  } catch (error) {
    console.error('Native platform initialization failed:', error)
    Sentry.captureException(error)
    return false
  }
}

const initializeTransmit = authStore => {
  const transmit = createTransmit(() => authStore.token)

  transmit.on('connection', connection => {
    console.log('[Transmit Service] Connection established.')
    connection.on('ready', () =>
      console.log('[Transmit Service] Connection ready.')
    )
    connection.on('error', error =>
      console.error('[Transmit Service] Connection error:', error)
    )
    connection.on('close', () =>
      console.log('[Transmit Service] Connection closed.')
    )
  })

  return transmit
}

const initializeApp = async () => {
  const app = createApp(App)
  const pinia = createPinia()

  // Create sliding window container before app mount
  if (!document.getElementById('sliding-window-container')) {
    const slidingWindowContainer = document.createElement('div')
    slidingWindowContainer.id = 'sliding-window-container'
    slidingWindowContainer.style.position = 'relative'
    slidingWindowContainer.style.zIndex = '9999'
    document.body.appendChild(slidingWindowContainer)
  }

  pinia.use(({ store }) => {
    store.$subscribe((mutation, state) => {
      if (
        process.env.NODE_ENV === 'development' &&
        import.meta.env.VITE_DEBUG_PINIA === 'true'
      ) {
        console.log('Pinia mutation:', mutation.type, state)
      }
    })
  })

  if (process.env.NODE_ENV === 'development') {
    window.Vue = app
  }

  app.use(router)
  app.use(pinia)

  initializeSentry(app)

  const authStore = useAuthStore()

  // Mount the app immediately with minimal setup
  try {
    await router.isReady()
    const transmit = initializeTransmit(authStore)
    app.provide('transmit', transmit)
  } catch (error) {
    console.error('Initial setup failed:', error)
    Sentry.captureException(error)
  }

  app.use(Notifications)
  app.use(Vue3TouchEvents, {
    passive: true,
    touchHoldTolerance: 400,
    touchClass: '',
    tapTolerance: 10,
    swipeTolerance: 30,
    longTapTimeOut: 400,
    touchOnly: true,
    disableClick: false,
    namespace: 'touch-events',
  })

  app.config.errorHandler = handleError
  app.config.globalProperties.$log = logger

  initializePWA()
  initializeNativePlatform() // Run in background

  // Mount before auth to avoid delay, handle auth in background
  app.mount('#app')

  // Initialize auth in background
  authStore.initAuth().catch(error => {
    console.error('Auth initialization error:', error)
    Sentry.captureException(error)
  })

  return app
}

void initializeApp().catch(error => {
  console.error('Application initialization failed:', error)
  Sentry.captureException(error)
})
