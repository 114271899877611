import { computed } from 'vue'
import { Capacitor } from '@capacitor/core'

export function usePlatform() {
  const isCapacitor = computed(() => Capacitor.isNativePlatform())
  const isIOS = computed(
    () => isCapacitor.value && Capacitor.getPlatform() === 'ios'
  )
  const isAndroid = computed(
    () => isCapacitor.value && Capacitor.getPlatform() === 'android'
  )

  async function showMediacastPicker() {
    if (isIOS.value) {
      try {
        const { default: Mediacast } = await import(
          '@/plugins/mediacast-plugin'
        )
        await Mediacast.showMediacastPicker()
      } catch (error) {
        console.error('Error showing Mediacast picker:', error)
      }
    }
  }

  return {
    isCapacitor,
    isIOS,
    isAndroid,
    showMediacastPicker,
  }
}
