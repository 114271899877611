/* global caches, Response */
import { ref } from 'vue'

function getCacheConfig() {
  const env =
    window.location.hostname === 'localhost'
      ? '-local'
      : window.location.hostname.includes('dev.')
        ? '-dev'
        : ''
  const MAX_AGE_SECONDS = 7 * 24 * 60 * 60
  const config = {
    stories: `talebly-stories${env}-v1`,
    storyImages: `talebly-story-images${env}-v1`,
    maxAge: MAX_AGE_SECONDS,
  }
  console.log('🔧 Story Cache config:', config)
  return config
}

const CACHE_CONFIG = getCacheConfig()
export { CACHE_CONFIG }

export function getApiBaseUrl() {
  const hostname = window.location.hostname
  if (hostname === 'localhost') {
    return 'http://localhost:5173'
  } else if (hostname.includes('dev.')) {
    return 'https://api.dev.talebly.com'
  }
  return 'https://api.talebly.com'
}

const CACHE_VERSIONS = {
  stories: 'v1',
  storyImages: 'v1',
}

function normalizeUrl(url) {
  return url.startsWith('/') ? url : `/${url}`
}

function _validateCacheStructure(data) {
  return (
    data &&
    typeof data === 'object' &&
    data.version === CACHE_VERSIONS.stories &&
    (Array.isArray(data.stories) || data.data)
  )
}

async function _cacheStory(id, data) {
  if ('caches' in window) {
    try {
      const cache = await caches.open(CACHE_CONFIG.stories)
      const normalizedUrl = normalizeUrl(`/api/stories/${id}`)
      const cacheData = {
        version: CACHE_VERSIONS.stories,
        timestamp: Date.now(),
        data,
      }
      await cache.put(
        normalizedUrl,
        new Response(JSON.stringify(cacheData), {
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': `max-age=${CACHE_CONFIG.maxAge}`,
            'X-Cache-Version': CACHE_VERSIONS.stories,
          },
        })
      )
    } catch (error) {
      console.warn('Story cache operation failed:', error)
    }
  }
}

async function _batchCacheOperations(operations) {
  const BATCH_SIZE = 5
  const results = []
  for (let i = 0; i < operations.length; i += BATCH_SIZE) {
    const batch = operations.slice(i, i + BATCH_SIZE)
    const batchResults = await Promise.all(
      batch.map(async op => {
        try {
          return await op()
        } catch (error) {
          console.warn('Batch operation failed:', error)
          return null
        }
      })
    )
    results.push(...batchResults)
    if (i + BATCH_SIZE < operations.length) {
      await new Promise(resolve => setTimeout(resolve, 100))
    }
  }
  return results
}

export function useStoryCache() {
  const isStoryCached = ref({})
  const imageQueue = ref([])
  let isProcessing = false

  async function cacheStoryImages(stories) {
    if (!('caches' in window)) {
      console.log('🛑 Cache API not available')
      return
    }

    const validStories = stories.filter(
      s => s.bookCoverUrl && typeof s.bookCoverUrl === 'string'
    )
    if (validStories.length === 0) {
      console.log('📸 No valid story cover URLs to cache')
      return
    }

    imageQueue.value.push(...validStories)
    if (isProcessing) {
      console.log('📸 Image caching already in progress, queuing...')
      return
    }
    isProcessing = true

    try {
      console.log(
        `📸 Starting to cache ${imageQueue.value.length} story cover images`
      )
      const cache = await caches.open(CACHE_CONFIG.storyImages)
      const cacheKeys = await cache.keys()
      const cachedUrls = new Set(cacheKeys.map(request => request.url))

      while (imageQueue.value.length > 0) {
        const batch = imageQueue.value.splice(0, 3)
        const urlsToCache = batch
          .map(story => story.bookCoverUrl)
          .filter(url => !cachedUrls.has(url))

        if (urlsToCache.length === 0) {
          console.log('📸 All images in batch already cached, skipping fetch')
          continue
        }

        console.log(
          `📸 Fetching ${urlsToCache.length} uncached images in batch`
        )
        await Promise.all(
          urlsToCache.map(async url => {
            const story = batch.find(s => s.bookCoverUrl === url)
            console.log(`Fetching image for story ${story.id}: ${url}`)

            try {
              const response = await fetch(url, {
                mode: 'no-cors',
                credentials: 'omit',
              })

              console.log(`Response for ${url}: type=${response.type}`)
              await cache.put(url, response.clone())
              const cachedResponse = await cache.match(url)
              if (cachedResponse) {
                console.log(
                  `✅ Successfully cached image for story ${story.id}`
                )
              } else {
                console.error(`❌ Cache entry not created for ${url} after put`)
              }
              cachedUrls.add(url) // Update cached URLs set
            } catch (error) {
              console.error(
                `❌ Error caching image for story ${story.id}:`,
                error
              )
            }
          })
        )
        await new Promise(resolve => setTimeout(resolve, 100))
      }
    } catch (error) {
      console.error('📸 Fatal error in cacheStoryImages:', error)
    } finally {
      isProcessing = false
      console.log('📸 Finished processing story image cache queue')
    }
  }

  async function checkStoryAvailability(storyId) {
    if (!('caches' in window)) return false

    try {
      const cache = await caches.open(CACHE_CONFIG.stories)
      const apiUrl = `${getApiBaseUrl()}/api/stories/${storyId}`
      const cachedResponse = await cache.match(apiUrl)

      if (cachedResponse) {
        const cacheDate = new Date(
          cachedResponse.headers.get('date') || Date.now()
        )
        const now = new Date()
        if (now.getTime() - cacheDate.getTime() > CACHE_CONFIG.maxAge * 1000) {
          await cache.delete(apiUrl)
          return false
        }
        return true
      }
      return false
    } catch (err) {
      console.error('Failed to check story cache:', err)
      return false
    }
  }

  async function updateCacheStatus(stories) {
    if (!('caches' in window)) return

    try {
      const cache = await caches.open(CACHE_CONFIG.stories)
      await Promise.all(
        stories.map(async story => {
          const apiUrl = `${getApiBaseUrl()}/api/stories/${story.id}`
          const cachedResponse = await cache.match(apiUrl)

          if (cachedResponse) {
            const cacheDate = new Date(
              cachedResponse.headers.get('date') || Date.now()
            )
            const now = new Date()
            isStoryCached.value[story.id] =
              now.getTime() - cacheDate.getTime() <= CACHE_CONFIG.maxAge * 1000
          } else {
            isStoryCached.value[story.id] = false
          }
        })
      )
    } catch (error) {
      console.warn('Failed to update story cache status:', error)
    }
  }

  async function clearCache() {
    if (!('caches' in window)) return

    try {
      await Promise.all([
        window.caches.delete(CACHE_CONFIG.stories),
        window.caches.delete(CACHE_CONFIG.storyImages),
      ])
      isStoryCached.value = {}
      console.log('🧹 Cleared story caches')
    } catch (error) {
      console.error('Failed to clear story caches:', error)
    }
  }

  return {
    isStoryCached,
    cacheStoryImages,
    checkStoryAvailability,
    updateCacheStatus,
    clearCache,
  }
}
