<template>
  <div class="none-layout">
    <slot></slot>
  </div>
</template>

<script setup>
// None layout setup here
</script>

<script>
export default {
  name: 'NoneLayout',
}
</script>

<style scoped>
.none-layout {
  min-height: 100vh; /* Ensure it fills at least the viewport height */
  display: flex;
  flex-direction: column;
}
</style>
