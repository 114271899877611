/* global caches, Response */
import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import apiClient from '@/utils/apiClient'
import { useAuthStore } from '@/stores/auth'
import { createStoreLogger } from '@/utils/logger'
import { handleStoreError } from '@/utils/errorHandler'
import { CACHE_CONFIG, getApiBaseUrl } from '@/composables/useStoryCache' // Import getApiBaseUrl

const log = createStoreLogger('StoryStore')

const CACHE_VERSIONS = {
  stories: 'v1',
}

const MAX_QUEUE_SIZE = 50

export const useStoryStore = defineStore('story', () => {
  const authStore = useAuthStore()
  const stories = ref([])
  const inProgressStories = ref([])
  const storyDetails = ref(null)
  const configOptions = ref({
    languages: [],
    storyTypes: [],
    writingStyles: [],
    readerAges: [],
    castMembers: [],
    voices: [],
  })
  const defaultConfig = ref({
    languageAttributeId: '',
    storyTypeAttributeId: '',
    writingStyleAttributeId: '',
    readerAgeAttributeId: '',
    numParagraphs: 10,
    prompt: '',
    defaultCastMemberIds: [],
    voiceId: '',
  })
  const form = ref({
    config: {
      languageAttributeId: '',
      storyTypeAttributeId: '',
      writingStyleAttributeId: '',
      readerAgeAttributeId: '',
      numParagraphs: 5,
      prompt: '',
      castMemberIds: [],
      voiceId: '',
      perspective: 'third',
      firstPersonCharacterId: null,
    },
  })
  const loading = ref(false)
  const error = ref(null)
  const success = ref(null)
  const subscription = ref(null)
  const loadingStates = ref(new Map())
  const fetchQueue = new Map()
  const hasInitialized = ref(false)
  const _transmitSubscriptionStatus = ref({
    isSubscribed: false,
    lastError: null,
    reconnectAttempts: 0,
  })
  const storeVersion = ref(0)

  let _transmitInstance = null

  function addOrUpdateStory(story) {
    const index = stories.value.findIndex(s => s.id === story.id)
    if (index === -1) {
      stories.value = [...stories.value, { ...story, isNew: true }]
      storeVersion.value++
    } else if (JSON.stringify(stories.value[index]) !== JSON.stringify(story)) {
      stories.value[index] = { ...story }
      storeVersion.value++
    }
  }

  async function fetchStories() {
    try {
      const response = await apiClient.get('/stories')
      const newStories = Array.isArray(response) ? response : []
      if (JSON.stringify(newStories) !== JSON.stringify(stories.value)) {
        setStoriesWithoutAnimation(newStories)
        await cacheStoriesList(newStories)
      }
      return newStories
    } catch (error) {
      console.error('Error fetching stories:', error.message || error)
      return []
    }
  }

  async function fetchInProgressStories() {
    try {
      const response = await apiClient.get('/stories/in-progress')
      inProgressStories.value = response || []
      log.info('In-progress stories fetched', {
        count: inProgressStories.value.length,
      })
    } catch (err) {
      handleStoreError('StoryStore', 'fetchInProgressStories', err)
      inProgressStories.value = []
    }
  }

  async function fetchStoryDetails(id) {
    if (!id) {
      console.warn('Invalid story ID provided to fetchStoryDetails')
      return null
    }

    if (loadingStates.value.get(id)) {
      const existingQueue = fetchQueue.get(id) || []
      if (existingQueue.length >= MAX_QUEUE_SIZE) {
        return Promise.reject(new Error('Queue limit exceeded'))
      }
      return await new Promise((resolve, reject) => {
        fetchQueue.set(id, [...existingQueue, { resolve, reject }])
      })
    }

    loadingStates.value.set(id, true)
    try {
      const cachedStory = await getCachedStory(id)
      if (cachedStory) {
        if (!cachedStory.audioUrl) {
          const response = await apiClient.get(`/stories/${id}`)
          if (response) {
            await cacheStory(id, response)
            const queued = fetchQueue.get(id) || []
            queued.forEach(({ resolve }) => resolve(response))
            fetchQueue.delete(id)
            return response
          }
        }
        const queued = fetchQueue.get(id) || []
        queued.forEach(({ resolve }) => resolve(cachedStory))
        fetchQueue.delete(id)
        return cachedStory
      }

      const response = await apiClient.get(`/stories/${id}`)
      if (response) {
        if (!response.audioUrl) {
          console.error('Story response missing audioUrl:', response)
          throw new Error('Invalid story data received')
        }
        await cacheStory(id, response)
        const queued = fetchQueue.get(id) || []
        queued.forEach(({ resolve }) => resolve(response))
        fetchQueue.delete(id)
        return response
      }
    } catch (err) {
      const queued = fetchQueue.get(id) || []
      queued.forEach(({ reject }) => reject(err))
      fetchQueue.delete(id)
      handleStoreError('StoryStore', 'fetchStoryDetails', err)
      throw err
    } finally {
      loadingStates.value.delete(id)
    }
    return null
  }

  async function getCachedStory(id) {
    if ('caches' in window) {
      try {
        const cache = await caches.open(CACHE_CONFIG.stories)
        const normalizedUrl = `/api/stories/${id}`
        const response = await cache.match(normalizedUrl)
        if (response) {
          return response.json()
        }
      } catch (error) {
        console.warn('Failed to get cached story:', error.message || error)
        return null
      }
    }
    return null
  }

  async function cacheStory(id, data) {
    if ('caches' in window) {
      try {
        const cache = await caches.open(CACHE_CONFIG.stories)
        const normalizedUrl = `/api/stories/${id}`
        await cache.put(
          normalizedUrl,
          new Response(JSON.stringify(data), {
            headers: { 'Content-Type': 'application/json' },
          })
        )
        console.log(`📦 Story cached successfully: ${id}`)
      } catch (error) {
        console.warn('Failed to cache story:', error.message || error)
      }
    }
  }

  async function fetchConfigOptions() {
    loading.value = true
    try {
      configOptions.value = await apiClient.get('/stories/config-options')
      log.info('Story config options fetched', {
        languagesCount: configOptions.value.languages.length,
        storyTypesCount: configOptions.value.storyTypes.length,
      })
    } catch (err) {
      handleStoreError('StoryStore', 'fetchConfigOptions', err)
      error.value = 'Failed to fetch configuration options'
      throw err
    } finally {
      loading.value = false
    }
  }

  async function createStory() {
    loading.value = true
    error.value = null
    try {
      await apiClient.post('/stories/initiate', form.value)
      log.info('Story creation initiated', {
        language: form.value.config.languageAttributeId,
        storyType: form.value.config.storyTypeAttributeId,
      })
    } catch (err) {
      handleStoreError('StoryStore', 'createStory', err)
      error.value = 'Failed to initiate story creation'
      throw err
    } finally {
      loading.value = false
    }
  }

  function updateFormValue(key, value) {
    form.value.config[key] = value
    log.debug('Story form updated', { key, value })
  }

  async function subscribeToStoryUpdates(transmit) {
    if (!transmit) {
      log.error('Transmit instance not provided')
      return
    }

    if (subscription.value) {
      log.warn('Subscription already exists, cleaning up first')
      await unsubscribeFromStoryUpdates()
    }

    try {
      const channel = `user/${authStore.user.id}/stories`
      const newSubscription = transmit.subscription(channel)
      const processedMessages = new Set()

      newSubscription.onMessage(data => {
        console.log('📨 Received story update:', data)
        const messageKey = `${data.type}-${data.progressId || data.storyId}-${data.currentStage || ''}`
        if (processedMessages.has(messageKey)) {
          log.debug('Skipping duplicate message:', messageKey)
          return
        }
        processedMessages.add(messageKey)
        if (processedMessages.size > 1000) {
          processedMessages.clear()
        }

        log.info('Processing story update:', data)
        switch (data.type) {
          case 'story_creation_initiated':
            inProgressStories.value = [
              ...inProgressStories.value,
              {
                id: data.progressId,
                currentStage: data.currentStage,
                status: data.status,
              },
            ]
            break
          case 'story_progress_updated':
            inProgressStories.value = inProgressStories.value.map(story =>
              story.id === data.progressId
                ? {
                    ...story,
                    currentStage: data.currentStage,
                    status: data.status,
                  }
                : story
            )
            break
          case 'story_created':
          case 'story_updated':
            fetchStories()
            fetchInProgressStories()
            break
          case 'story_deleted':
            fetchStories()
            break
          default:
            break
        }
      })

      await newSubscription.create()
      subscription.value = newSubscription
      log.info('Subscribed to story updates', { channel })
    } catch (error) {
      log.error('Failed to subscribe to story updates:', error.message || error)
      throw error
    }
  }

  async function unsubscribeFromStoryUpdates() {
    if (subscription.value) {
      console.log('🔌 Starting unsubscribe process')
      try {
        await subscription.value.delete().catch(() => {})
        console.log('👋 Successfully unsubscribed from story updates')
        log.info('Unsubscribed from story updates')

        _transmitSubscriptionStatus.value.isSubscribed = false
        subscription.value = null
      } catch (error) {
        console.error(
          '❌ Failed to unsubscribe from story updates:',
          error.message || error
        )
        log.error('Failed to unsubscribe from story updates:', error)
      }
    }
  }

  async function clearGeneratingStories() {
    try {
      const inProgressIds = inProgressStories.value.map(story => story.id)
      await apiClient.post('/stories/clear-generating', { ids: inProgressIds })
      log.info('Generating stories cleared', { count: inProgressIds.length })
      await fetchInProgressStories()
      await fetchStories()
    } catch (err) {
      handleStoreError('StoryStore', 'clearGeneratingStories', err)
      throw err
    }
  }

  const completedStories = computed(() =>
    stories.value
      .filter(story => story.title)
      .map(story => ({
        ...story,
        language: story.storyConfig?.language?.name || 'Unknown',
        type: story.storyConfig?.storyType?.name || 'Unspecified',
        writingStyle: story.storyConfig?.writingStyle?.name || 'Unspecified',
        readerAge: story.storyConfig?.readerAge?.name || 'Unspecified',
        bookCoverUrl:
          story.bookCoverUrl || '/placeholder.svg?height=300&width=300',
      }))
  )

  const isFormValid = computed(() => {
    const { config } = form.value
    const isValid =
      config.languageAttributeId &&
      config.storyTypeAttributeId &&
      config.writingStyleAttributeId &&
      config.readerAgeAttributeId &&
      config.voiceId &&
      config.numParagraphs >= 5 &&
      config.numParagraphs <= 25 &&
      config.prompt.trim() !== '' &&
      (config.perspective === 'third' ||
        (config.perspective === 'first' &&
          config.firstPersonCharacterId !== null))
    log.debug('Form validation status:', { isValid, config })
    return isValid
  })

  async function loadCachedStories() {
    const cachedData = await getCachedStoriesList()
    const cachedStories = cachedData?.stories || []

    // Set cached stories immediately if available
    if (cachedStories.length > 0) {
      stories.value = cachedStories
      hasInitialized.value = true
    } else {
      // If no cache, fetch synchronously to ensure initial data
      const freshStories = await fetchStories()
      if (freshStories && freshStories.length > 0) {
        stories.value = freshStories
        hasInitialized.value = true
        await cacheStoriesList(freshStories)
      }
    }

    // Fetch fresh stories in the background if cache is older than 5 minutes
    if (!cachedData || Date.now() - cachedData.timestamp > 5 * 60 * 1000) {
      fetchStories()
        .then(freshStories => {
          if (
            freshStories &&
            freshStories.length > 0 &&
            JSON.stringify(freshStories) !== JSON.stringify(stories.value)
          ) {
            stories.value = freshStories
            cacheStoriesList(freshStories)
            console.info('Stories updated from network in background')
          }
        })
        .catch(err => {
          console.warn(
            'Background fetch failed, using cached data:',
            err.message || err
          )
        })
    } else {
      console.info('Cache is recent, skipping background fetch')
    }

    return { stories: stories.value }
  }

  function resetForm() {
    form.value = {
      config: {
        languageAttributeId: '',
        storyTypeAttributeId: '',
        writingStyleAttributeId: '',
        readerAgeAttributeId: '',
        numParagraphs: 5,
        prompt: '',
        castMemberIds: [],
        voiceId: '',
        perspective: 'third',
        firstPersonCharacterId: null,
      },
    }
  }

  function setStoriesWithoutAnimation(newStories) {
    if (JSON.stringify(stories.value) !== JSON.stringify(newStories)) {
      stories.value = newStories
      storeVersion.value++
    }
  }

  function updateStoryInPlace(updatedStory) {
    const index = stories.value.findIndex(s => s.id === updatedStory.id)
    if (index !== -1) {
      Object.assign(stories.value[index], updatedStory)
    }
  }

  async function initializeTransmit(transmitClient) {
    if (!transmitClient) {
      console.warn('⚠️ No transmit client provided for initialization')
      return
    }

    if (_transmitSubscriptionStatus.value.isSubscribed) {
      console.log('🔄 Transmit subscription already active')
      return
    }

    _transmitInstance = transmitClient

    try {
      await unsubscribeFromStoryUpdates()

      console.log('📡 Setting up new subscription')
      await subscribeToStoryUpdates(_transmitInstance)

      console.log('📥 Fetching initial data')
      await Promise.all([fetchStories(), fetchInProgressStories()])

      _transmitSubscriptionStatus.value.isSubscribed = true
      _transmitSubscriptionStatus.value.lastError = null
      _transmitSubscriptionStatus.value.reconnectAttempts = 0

      console.log('✅ Transmit initialization complete')
    } catch (error) {
      _transmitSubscriptionStatus.value.lastError = error
      console.error('💥 Failed to initialize transmit:', error.message || error)
      throw error
    }
  }

  function extractIdFromKey(key) {
    const matches = key.match(/\/stories\/(\d+)$/)
    return matches ? parseInt(matches[1]) : null
  }

  function buildFallbackResponse(id) {
    return {
      id,
      title: 'Temporarily Unavailable',
      content: 'This story is currently unavailable. Please try again later.',
      isPlaceholder: true,
    }
  }

  async function invalidateCache(reason = 'manual') {
    try {
      const cache = await caches.open(CACHE_CONFIG.stories)
      const keys = await cache.keys()
      const updatedIds = new Set()

      switch (reason) {
        case 'version_mismatch': {
          for (const key of keys) {
            const response = await cache.match(key)
            const data = await response.json()
            if (data.version !== CACHE_VERSIONS.stories) {
              await cache.delete(key)
            }
          }
          break
        }
        case 'data_update': {
          for (const key of keys) {
            if (updatedIds.has(extractIdFromKey(key))) {
              await cache.delete(key)
            }
          }
          break
        }
        default:
          await cache
            .keys()
            .then(keys => Promise.all(keys.map(key => cache.delete(key))))
      }
      console.info('Cache invalidated:', reason)
    } catch (error) {
      console.error('Story cache invalidation failed:', error.message || error)
    }
  }

  async function fetchWithRecovery(id) {
    try {
      const cachedData = await getCachedStory(id)
      if (cachedData) return cachedData

      const networkData = await fetchStoryDetails(id)
      if (networkData) {
        await cacheStory(id, networkData)
        return networkData
      }

      throw new Error('All recovery attempts failed')
    } catch (error) {
      console.error('Story fetch failed:', error.message || error)
      return buildFallbackResponse(id)
    }
  }

  async function cacheStoriesList(storiesList) {
    if ('caches' in window) {
      try {
        const cache = await caches.open(CACHE_CONFIG.stories)
        console.log(`📦 Opening cache: ${CACHE_CONFIG.stories}`)
        const listUrl = `${getApiBaseUrl()}/api/stories` // Use imported getApiBaseUrl
        const cacheData = {
          version: CACHE_VERSIONS.stories,
          timestamp: Date.now(),
          stories: storiesList,
        }
        await cache.put(
          listUrl,
          new Response(JSON.stringify(cacheData), {
            headers: {
              'Content-Type': 'application/json',
              'Cache-Control': `max-age=${CACHE_CONFIG.maxAge}`,
            },
          })
        )
        console.info('Cached stories list successfully.')
      } catch (error) {
        console.warn('Failed to cache stories list:', error.message || error)
      }
    }
  }

  async function getCachedStoriesList() {
    if ('caches' in window) {
      try {
        const cache = await caches.open(CACHE_CONFIG.stories)
        const listUrl = `${getApiBaseUrl()}/api/stories` // Use imported getApiBaseUrl
        const response = await cache.match(listUrl)
        if (response) {
          const data = await response.json()
          if (data.version === CACHE_VERSIONS.stories) {
            console.info('Loaded stories list from cache.')
            return data
          }
          await cache.delete(listUrl)
        }
      } catch (error) {
        console.error(
          'Error retrieving cached stories list:',
          error.message || error
        )
        return null
      }
    }
    return null
  }

  async function revalidateStories() {
    try {
      const freshStories = await apiClient.get('/stories', {
        useCache: true,
        skipVersionCheck: true,
        forceCacheOnOffline: true,
        timeout: 1000,
      })

      if (
        freshStories &&
        JSON.stringify(freshStories) !== JSON.stringify(stories.value)
      ) {
        stories.value = freshStories
        await cacheStoriesList(freshStories)
        console.info('Stories updated from network')
      }
    } catch (error) {
      console.error('Error revalidating stories:', error.message || error)
    }
  }

  return {
    stories,
    inProgressStories,
    storyDetails,
    configOptions,
    defaultConfig,
    form,
    loading,
    error,
    success,
    fetchStories,
    fetchInProgressStories,
    fetchStoryDetails,
    fetchConfigOptions,
    createStory,
    subscribeToStoryUpdates,
    unsubscribeFromStoryUpdates,
    clearGeneratingStories,
    completedStories,
    updateFormValue,
    isFormValid,
    loadCachedStories,
    resetForm,
    addOrUpdateStory,
    setStoriesWithoutAnimation,
    updateStoryInPlace,
    hasInitialized,
    initializeTransmit,
    invalidateCache,
    fetchWithRecovery,
    cacheStoriesList,
    getCachedStoriesList,
    revalidateStories,
  }
})
