<template>
  <div class="flex flex-col h-full pt-safe-top">
    <!-- TopNavbar for Desktop -->
    <TopNavbar
      v-if="isDesktop"
      :is-mobile="isMobile"
      :is-tablet="isTablet"
      py-2
    />

    <!-- Main Content Area -->
    <div class="flex-1 flex flex-col overflow-hidden relative">
      <main
        :class="[
          'flex-1 overflow-y-auto pt-2',
          isMobile || isTablet
            ? isAndroid
              ? 'pb-[calc(61px+49px+var(--safe-area-inset-bottom))]' // Android with safe area
              : 'pb-[calc(61px+49px+env(safe-area-inset-bottom))]' // iOS with safe area
            : 'pb-14', // Desktop padding for audio player only
        ]"
      >
        <div
          :class="[
            'mx-auto w-full',
            { 'max-w-8xl px-4': isDesktop },
            { 'px-4': !isDesktop },
          ]"
        >
          <slot></slot>
        </div>
      </main>
    </div>

    <!-- Fixed position elements at the bottom -->
    <div class="fixed bottom-0 left-0 right-0 z-30" style="isolation: normal">
      <ResponsiveAudioPlayer
        :has-bottom-navbar="isMobile || isTablet"
        class="flex-shrink-0"
      />
      <BottomNavbar
        v-if="isMobile || isTablet"
        :is-mobile="isMobile"
        :is-tablet="isTablet"
        :is-capacitor="isCapacitor"
        :is-i-o-s="isIOS"
        :is-android="isAndroid"
        class="flex-shrink-0"
      />
      <div
        :class="[
          { 'bg-gray-100': !isAndroid, 'bg-white': isAndroid },
          { 'pb-2': !isIOS && !isAndroid && (isMobile || isTablet) },
          { 'pb-safe-bottom': isIOS || isAndroid },
        ]"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineAsyncComponent, computed, watch } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useResponsive } from '@/composables/useResponsive'
import ResponsiveAudioPlayer from '@/components/ResponsiveAudioPlayer.vue'
import { StatusBar, Style } from '@capacitor/status-bar'
import { useAppStore } from '@/stores/app'
import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'

const TopNavbar = defineAsyncComponent(
  () => import('@/components/TopNavbar.vue')
)
const BottomNavbar = defineAsyncComponent(
  () => import('@/components/BottomNavbar.vue')
)

const { isMobile, isTablet, isDesktop } = useResponsive()
const showOnboardingModal = ref(false)
const authStore = useAuthStore()
const appStore = useAppStore()

const isCapacitor = computed(() => Capacitor.isNativePlatform())
const isIOS = computed(() => Capacitor.getPlatform() === 'ios')
const isAndroid = computed(
  () => isCapacitor.value && Capacitor.getPlatform() === 'android'
)

onMounted(async () => {
  if (isCapacitor.value) {
    if (isIOS.value) {
      try {
        await StatusBar.setStyle({ style: Style.Dark })
        await StatusBar.setBackgroundColor({ color: '#FAFAFA' })
        await StatusBar.show()
      } catch (error) {
        console.error('Error setting status bar:', error)
      }
    }
    // For Android, we're using the SafeArea plugin configured in main.js
  }

  if (authStore.isAuthenticated && authStore.requiresOnboarding) {
    showOnboardingModal.value = true
  }
})

watch(
  () => appStore.appInitialized,
  async isInitialized => {
    if (isInitialized && isCapacitor.value) {
      try {
        await SplashScreen.hide({
          fadeOutDuration: 500,
        })
      } catch (error) {
        console.error('Error hiding splash screen:', error)
      }
    }
  }
)
</script>

<style scoped>
.mt-safe-top {
  margin-top: var(--safe-area-inset-top);
}

.pt-safe-top {
  padding-top: var(--safe-area-inset-top);
}

.pb-safe-bottom {
  padding-bottom: var(--safe-area-inset-bottom);
}
</style>
