import { computed } from 'vue'

export function useProgress(currentTime, duration) {
  const progressPercentage = computed(() => {
    return duration.value > 0 ? (currentTime.value / duration.value) * 100 : 0
  })

  const formattedCurrentTime = computed(() => formatTime(currentTime.value))
  const formattedDuration = computed(() => formatTime(duration.value))

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.floor(seconds % 60)
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
  }

  function calculateSeekPosition(event, element) {
    const rect = element.getBoundingClientRect()
    const clickX = event.clientX - rect.left
    return clickX / rect.width
  }

  return {
    progressPercentage,
    formattedCurrentTime,
    formattedDuration,
    formatTime,
    calculateSeekPosition,
  }
}
